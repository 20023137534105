exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-home-runner-work-new-gatsby-blog-new-gatsby-blog-blog-content-amplify-next-mui-1-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/new-gatsby-blog/new-gatsby-blog/blog-content/amplify-next-mui-1/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-home-runner-work-new-gatsby-blog-new-gatsby-blog-blog-content-amplify-next-mui-1-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-home-runner-work-new-gatsby-blog-new-gatsby-blog-blog-content-books-read-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/new-gatsby-blog/new-gatsby-blog/blog-content/books-read/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-home-runner-work-new-gatsby-blog-new-gatsby-blog-blog-content-books-read-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-home-runner-work-new-gatsby-blog-new-gatsby-blog-blog-content-git-tag-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/new-gatsby-blog/new-gatsby-blog/blog-content/git-tag/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-home-runner-work-new-gatsby-blog-new-gatsby-blog-blog-content-git-tag-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-home-runner-work-new-gatsby-blog-new-gatsby-blog-blog-content-slack-search-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/new-gatsby-blog/new-gatsby-blog/blog-content/slack-search/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-home-runner-work-new-gatsby-blog-new-gatsby-blog-blog-content-slack-search-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-home-runner-work-new-gatsby-blog-new-gatsby-blog-blog-content-zisou-programmer-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/new-gatsby-blog/new-gatsby-blog/blog-content/zisou-programmer/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-home-runner-work-new-gatsby-blog-new-gatsby-blog-blog-content-zisou-programmer-index-mdx" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */)
}

